import { Col, Form, Modal, Row } from "react-bootstrap"
import { formatDateAndTimeToISO, formatDateTimeToDDMMYY, formatDateTimeToHHMM, formatDateTimeToYYYYMMDD } from "../../utils/formatDateTime"
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { postRequest } from "../../utils/apiRequest";

export const EnrollmentDetail = ({ show, setShow, data, index, token, refetch }) => {

    const [formData, setFormData] = useState({
        cost: '',
        id: '',
        paymentStatus: '',
        paymentType: '',
        paidAt: ''
    });
    const [showToast, setShowToast] = useOutletContext();

    useEffect(() => {
        setFormData({
            cost: data.participants[index].cost,
            id: data.participants[index].id,
            paymentStatus: data.participants[index].paymentStatus,
            paymentType: data.participants[index].paymentType,
            paidAt: data.participants[index].paidAt
        })
    }, [index]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            ...formData,
            paymentStatus: 'paid',
            paymentType: formData.paymentType == null ? 'cash' : formData.paymentType,
            paidAt: formData.paidAt == null ? new Date() : formatDateAndTimeToISO(formData.paidAt) 
        }
        const response = await postRequest('/auth/update-enrollment', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger'
            })
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Zahlung erfolgreich gespeichert. 💸',
                toastAppearance: 'success'
            });
            refetch();
            setShow(false);
        }
    }

    return (
        <Modal 
            show={show} 
            onHide={() => setShow(false)} 
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{data.participants[index].child.firstName} {data.participants[index].child.lastName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <h5>Zahlung verbuchen</h5>
                    </Col>
                </Row>

                <Row className="">
                    <Col>
                        Kurs: {data.title}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        Ort: {data.locationStreet} {data.locationStreetNumber}, {data.locationPostalCode} {data.locationCity}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        Datum: {formatDateTimeToDDMMYY(data.date)}
                    </Col>
                </Row>

                <Row className="border-bottom pb-3">
                    <Col>
                        Uhrzeit: {formatDateTimeToHHMM(data.date)}
                    </Col>
                </Row>

                <Row className="mt-3 ps-3 pe-3">
                    <Col
                        className={
                            `${data.participants[index].paymentStatus == "paid" ? "bg-success" : "bg-danger"}
                            p-2 rounded
                            `
                        }
                    >
                        {data.participants[index].paymentStatus == "paid"
                        ?
                            <p className="text-center m-0">Dieser Kurs wurde bereits bezahlt.</p>
                        :
                            <p className="text-center m-0">Kurskosten offen</p>
                        }
                    </Col>
                </Row>

                <Form className="mt-3" onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} >
                            <Form.Label>{"Kurskosten"}:</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.cost}
                                onChange={handleChange}
                                name="cost"
                            ></Form.Control>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className="mt-3">
                            <Form.Label>{"Zahlungsart:"}</Form.Label>
                            <Form.Select
                                value={formData.paymentType ? formData.paymentType : 'cash'}
                                onChange={handleChange}
                                name="paymentType"
                            >
                                <option value={'cash'}>Bar</option>
                                <option value={'banktransfer'}>Überweisung</option>
                                <option value={'paypal'}>PayPal</option>
                                <option value={'card'}>Karte</option>
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className="mt-3">
                            <Form.Label>{"Zahldatum:"}</Form.Label>
                            <Form.Control
                                type="date"
                                value={formData.paidAt ? formatDateTimeToYYYYMMDD(formData.paidAt) : formatDateTimeToYYYYMMDD(new Date())}
                                onChange={handleChange}
                                name="paidAt"
                            ></Form.Control>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mt-3">
                            <Form.Control
                                type="submit"
                                value={"Speichern"}
                                style={{backgroundColor: '#0B6EFD', border: 'none'}}
                            ></Form.Control>
                        </Col>
                    </Row>

                </Form>
                
            </Modal.Body>
        </Modal>
    )
}